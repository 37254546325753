'use client';
const NotFound = () => {
    return (
        <div className="container">
          <div className="content">
            <img src="/assets/images/alethaLogoWhite.svg" alt="Logo" className="logo" />
            <h1>404</h1>
            <p>Sorry this page no longer exists,<br /> 
            please visit our homepage.</p>
            <a href="/" className="back-button">
              Back Home</a>
      
          </div>
          <style jsx>{`
        .container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          background-color: #000;
          color: #fff;
          text-align: center;
        }

        .content {
          max-width: 500px;
          padding: 20px;
        }

        .logo {
          width: 120px;
          margin: 0 auto 30px;
        }

        h1 {
          font-size: 3.5rem;
          font-weight: bold;
          margin: 0;
        }

        p {
          font-size: 1.5rem;
          margin: 20px 0;
        }

        .back-button {
          display: inline-block;
          padding: 10px 20px;
          background-color: #e63946;
          color: #fff;
          text-decoration: none;
          font-size: 1rem;
          font-weight: bold;
          border-radius: 5px;
          margin-top: 20px;
          transition: background-color 0.3s ease;
        }

        .back-button:hover {
          background-color: #d62828;
        }
      `}</style>
        </div>
      );
   
  };
  
  export default NotFound;
  